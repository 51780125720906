<div class="com-table">
  <div class="com-table_container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-mdc-table--spacing" multiTemplateDataRows>
      <ng-container matColumnDef="offer">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-offer-row">{{'COM.LABELS.OFFER_NUM' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="com-table_container--offer-column">
          <b>{{ element.index }}</b>
        </td>
      </ng-container>
      <ng-container matColumnDef="time_period">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">{{ 'COM.LABELS.TIME_FRAME' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="com-table_container--time-column">
          <b>{{ { inputTime: element.timePeriodKey, timeGap: (groupedData?.regConfig)!.intervalFrequency } | mapTime }}</b>
          ({{ timezoneAbbr }})
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">{{ column | weekDay }} {{ dates[i] | suffixDate }}</th>
        <td mat-cell *matCellDef="let element" class="com-table_container--columns">
          <div class="td-cell_readonly com-table_container--columns-readonly" [class.com-table_container--columns-readonly-last]="i === 6">
            <div class="td-cell_readonly-container" [class.offer-type-forecast]="element[columns[i]].clearedOfferType === 'FORECAST'">
              <span class="td-cell_readonly-body">
                <div style="display: flex;">
                  <dr-customer-offers-ui-icons *ngIf="element[columns[i]].is_dst_sub_hr || element[columns[i]].is_dst_add_hr" [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
                </div>
              </span>
              <span class="td-cell_readonly-body">{{ element[columns[i]].cleared }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="right_actions" *ngIf="hasClearedOffers">
        <th  mat-header-cell *matHeaderCellDef class="com-table_container--header-row mw-24"></th>
        <td mat-cell *matCellDef="let element" class="com-table_container--columns" >
          <ng-container *ngIf="element.clearedOffers && element.clearedOffers.length > 0">
            <div class="right-actions-col">
              <div>
                <mat-icon *ngIf="expandedRow === element">keyboard_arrow_up</mat-icon>     
                <mat-icon *ngIf="expandedRow !== element">keyboard_arrow_down</mat-icon>     
               </div>
            </div>           
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="rowExpandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div [@detailExpand]="element === expandedRow ? 'expanded' : 'collapsed'">
            <table class="com-cleared-table-detail" [ngClass]="{ 'pt-3': element !== expandedRow }" [cellSpacing]="0">
              <tr *ngFor="let offer of element.clearedOffers">
                <td>
                  <div>
                    <b>{{ 'COM.TABS.CLEARED.' + offer.type | translate }}</b>
                  </div>
                </td>
                <td *ngFor="let day of offer.days">
                  <div>{{ (day.price | currency : day.currency) || '-' }}</div>
                </td>
                <td  class="row-exp-right-action">
                </td>
              </tr>
            </table>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header-row"></tr>
      <tr
        mat-row
        (click)="expandedRow = expandedRow === row ? null : row"
        *matRowDef="let row; columns: displayedColumns"
        class="table-element-row"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['rowExpandedDetail']" class="table-detail-row"></tr>
    </table>
  </div>
  <div class="com-table_footer">
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="forcast-icon.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.FORECASTED_CLEARED' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="beach_access.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.HOLIDAY' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.DAYLIGHT_SAVINGS' | translate }}</span>
    </div>
  </div>
</div>
