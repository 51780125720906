import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScheduleDatesInterface } from '@dr-customer-offers-ui/lib-interfaces';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DatePickerEnum, DatePickerType, NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { BulkType, NgxIntervalDataGridRowModel, Week } from 'ngx-interval-data-grid';
import { BulkInput, GroupedData, InputTypeEnum, MinuteFrequencyOption, PeriodOption, PeriodOptionEnum, PeriodOptionValue, RegConfig, TabOption, TabOptionsEnum } from '../../../shared/models';
import { InternalService } from '../../../shared/services/internal.service';
import { MixPanelService } from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-bulk-selection',
  templateUrl: './bulk-selection.component.html',
  styleUrls: ['./bulk-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkSelectionComponent implements OnDestroy {

  protected startHour12 = 12;
  protected startPeriod = 'am';
  protected startMinute = 0;

  protected endHour12 = 12;
  protected endPeriod = 'am'
  protected endMinute = 0;

  protected bulkValue!: number | undefined;
  protected optOut = false;

  protected minStartDate!: Date | undefined;
  protected minEndDate!: Date | undefined;
  protected maxStartDate!: Date | undefined;
  protected maxEndDate!: Date | undefined;

  private minuteFrequency = 30;
  protected maxBulkValue!: number;
  protected minBulkValue!: number;

  protected invalidFormName: string | undefined;
  protected error = '';
  protected selectedType: DatePickerType = DatePickerEnum.DatePicker;
  protected selectedTabOption!: TabOption;
  protected TabOptionsEnum = TabOptionsEnum;
  protected regConfig!: RegConfig | null;

  protected periodOptions: PeriodOption[] = [
    { value: PeriodOptionEnum.Sunday, label: this.translate.instant('COM.LABELS.SUNDAY'), hidden: false },
    { value: PeriodOptionEnum.Monday, label: this.translate.instant('COM.LABELS.MONDAY'), hidden: false },
    { value: PeriodOptionEnum.Tuesday, label: this.translate.instant('COM.LABELS.TUESDAY'), hidden: false },
    { value: PeriodOptionEnum.Wednesday, label: this.translate.instant('COM.LABELS.WEDNESDAY'), hidden: false },
    { value: PeriodOptionEnum.Thursday, label: this.translate.instant('COM.LABELS.THURSDAY'), hidden: false },
    { value: PeriodOptionEnum.Friday, label: this.translate.instant('COM.LABELS.FRIDAY'), hidden: false },
    { value: PeriodOptionEnum.Saturday, label: this.translate.instant('COM.LABELS.SATURDAY'), hidden: false }
  ];
  protected startDaySelectedPeriod!: PeriodOptionValue;
  protected endDaySelectedPeriod!: PeriodOptionValue;
  protected scheduleData!: GroupedData | null;

  protected startDate: FormControl<NgxDateRangePickerOutput | null> = new FormControl(null);
  protected endDate: FormControl<NgxDateRangePickerOutput | null> = new FormControl(null);

  private startDateChange$;
  private endDateChange$;

  // This is just a cleaner approach to be more clear on start and end day. Can be removed if product dont like
  get startDayPeriodOptions(): PeriodOption[] {
    if (!this.endDaySelectedPeriod) {
      return this.periodOptions;
    }

    const endIndex = this.periodOptions.findIndex(option => option.value === this.endDaySelectedPeriod);

    return this.periodOptions.slice(0, endIndex + 1);
  }

  get endDayPeriodOptions(): PeriodOption[] {
    if (!this.startDaySelectedPeriod) {
      return this.periodOptions;
    }

    const startIndex = this.periodOptions.findIndex(option => option.value === this.startDaySelectedPeriod);

    return this.periodOptions.slice(startIndex);
  }

  constructor(private internalService: InternalService,
    @Inject(MAT_DIALOG_DATA) public data: {
      minuteFrequency: number,
      minBulkValue: number,
      maxBulkValue: number,
      minDate: moment.Moment,
      maxDate: moment.Moment,
      selectedTabOption: TabOption,
      scheduleData: GroupedData | null,
      regConfig: RegConfig | null;
    },
    public dialogRef: MatDialogRef<BulkSelectionComponent>,
    private translate: TranslateService,
    private mixPanelService: MixPanelService
  ) {
    this.initData(data);
    this.startDateChange$ = this.startDate.valueChanges.subscribe(this.startDateChange);
    this.endDateChange$ = this.endDate.valueChanges.subscribe(this.endDateChange);
  }

  ngOnDestroy(): void {
      this.startDateChange$.unsubscribe();
      this.endDateChange$.unsubscribe();
  }

  private initData(data: { minuteFrequency: number, minBulkValue: number, maxBulkValue: number, minDate: moment.Moment, maxDate: moment.Moment, selectedTabOption: TabOption, scheduleData: GroupedData | null, regConfig: RegConfig | null }) {
    this.selectedTabOption = data.selectedTabOption;
    this.regConfig = data.regConfig;
    // Check if the provided value is type MinuteFrecuencyOption
    if (this.isMinuteFrecuencyOption(data.minuteFrequency)) {
      this.minuteFrequency = data.minuteFrequency as MinuteFrequencyOption;
    } else {
      // If not, set default and throw an warning
      console.warn('The minuteFrequency should be one of the following numbers: 0, 5, 10, 15, 30. The default of 30 mins as frequency will be used.')
      this.minuteFrequency = 30;
    }

    this.maxBulkValue = data.maxBulkValue
    this.minBulkValue = data.minBulkValue


    if (data && moment.isMoment(data.minDate) && moment.isMoment(data.maxDate)) {
      this.minStartDate = data.minDate.toDate();
      this.minEndDate = data.minDate.toDate();
      this.maxStartDate = data.maxDate.toDate();
      this.maxEndDate = data.maxDate.toDate();
    }

    if(data.selectedTabOption.value === 'schedule') this.scheduleData = data.scheduleData;
  }

  protected incrementHour(type: string): void {
    // Increment the hours by 1 and update the converted time
    if (type === 'start') {
      if (this.startHour12 < 12) {
        this.startHour12++;
      } else {
        this.startHour12 = 1;
      }
    } else if (type === 'end') {
      if (this.endHour12 < 12) {
        this.endHour12++;
      } else {
        this.endHour12 = 1;
      }
    }
  }

  protected decrementHour(type: string): void {
    // Decrement the hours by 1 and update the converted time
    if (type === 'start') {
      if (this.startHour12 > 1) {
        this.startHour12--;
      } else {
        this.startHour12 = 12;
      }
    } else if (type === 'end') {
      if (this.endHour12 > 1) {
        this.endHour12--;
      } else {
        this.endHour12 = 12;
      }
    }
  }

  protected incrementMinuteByFrequency(type: string): void {
    const maxMinute = 59;
    let newMinute = 0;

    if (type === 'start') {
      newMinute = this.startMinute + this.minuteFrequency;

      if (newMinute <= maxMinute) {
        this.startMinute = newMinute;
      } else {
        // Handle the case where the new minute exceeds 59
        this.startMinute = 0;
      }
    } else if (type === 'end') {
      newMinute = this.endMinute + this.minuteFrequency;

      if (newMinute <= maxMinute) {
        this.endMinute = newMinute;
      } else {
        // Handle the case where the new minute exceeds 59
        this.endMinute = 0;
      }
    }
  }

  protected decrementMinuteByFrequency(type: string): void {
    const minMinute = 0;
    let newMinute = 0;

    if (type === 'start') {
      newMinute = this.startMinute - this.minuteFrequency;
      if (newMinute >= minMinute) {
        this.startMinute = newMinute;
      } else {
        // Handle the case where the new minute goes below 0
        this.startMinute = 60 - this.minuteFrequency;
      }
    } else if (type === 'end') {
      newMinute = this.endMinute - this.minuteFrequency;
      if (newMinute >= minMinute) {
        this.endMinute = newMinute;
      } else {
        // Handle the case where the new minute goes below 0
        this.endMinute = 60 - this.minuteFrequency;
      }
    }
  }

  private endDateChange(selectedDate: NgxDateRangePickerOutput | null): void {
    const date = selectedDate?.start?.toDate();
    if (date) {
      this.maxStartDate = date;
    }
  }

  private startDateChange(selectedDate: NgxDateRangePickerOutput | null): void {
    const date = selectedDate?.start?.toDate();
    if (date) {
      this.minEndDate = date;
    }
  }

  private isMinuteFrecuencyOption(myVar: number): myVar is MinuteFrequencyOption {
    return myVar === 0 || myVar === 5 || myVar === 10 || myVar === 15 || myVar === 30;
  }

  protected optOutChange(ev: MatCheckboxChange): void {
    if (ev.checked) {
      this.bulkValue = undefined;
    }
  }

  private checkIfDataIsValid(): boolean {
    const startDate = this.startDate.value?.start;
    const endDate = this.endDate.value?.start;

    // Input bulk value is greater than maximum allowed
    if (this.bulkValue && this.bulkValue > this.maxBulkValue) {
      this.invalidFormName = 'bulkValue';
      this.error = this.translate.instant('COM.ERRORS.FORM.INVALID_VALUE') + this.maxBulkValue;
      return false;
    }

    // No input bulk value and unselected OPT-OUT 
    if ((this.bulkValue === null || this.bulkValue === undefined) && !this.optOut) {
      this.invalidFormName = 'bulkValueOrOptOut';
      this.error = this.translate.instant('COM.ERRORS.FORM.EMPTY_VALUE');
      return false;
    }

    if (this.selectedTabOption.name !== 'Schedule') {
      if (!endDate || !startDate) {
        this.invalidFormName = 'startEndDate';
        this.error = this.translate.instant('COM.ERRORS.FORM.EMPTY_DATES');
        return false;
      }
      if (endDate.isBefore(startDate)) {
        this.invalidFormName = 'startEndDate';
        this.error = this.translate.instant('COM.ERRORS.FORM.END_DATE');
        return false;
      }
      if (!this.endDateTimeIsAfterStartDateTime(startDate, endDate)) {
        this.invalidFormName = 'startEndTime';
        this.error = this.translate.instant('COM.ERRORS.FORM.END_TIME');
        return false;
      }
      return true;
    }

    return true;
  }

  private endDateTimeIsAfterStartDateTime(startDate: moment.Moment, endDate: moment.Moment): boolean {
    // Validate values
    if (!startDate || !endDate) {
      return false;
    }

    const startWithHours = this.createMomentWithDateAndTime(startDate.toDate(), this.startHour12, this.startMinute, this.startPeriod);
    const endWithHours = this.createMomentWithDateAndTime(endDate.toDate(), this.endHour12, this.endMinute, this.endPeriod);

    return (endWithHours && startWithHours) ? endWithHours.isAfter(startWithHours) : false;
  }

  private createMomentWithDateAndTime(
    date: Date,
    hour12: number,
    minutes: number,
    period: string
  ): moment.Moment | undefined {
    // Validate input values
    if (
      isNaN(date.getTime()) || // Check if the date is valid
      hour12 < 1 || hour12 > 12 ||   // Check if the hour is in the valid 1-12 range
      minutes < 0 || minutes > 59 || // Check if minutes are in the valid 0-59 range
      (period !== 'am' && period !== 'pm') // Check if period is 'AM' or 'PM'
    ) {
      console.log('Invalid input values.');
      return undefined;
    }

    // Extract the day, month, and year from the input date
    const day = date.getDate();
    const month = date.getMonth() + 1; // JavaScript months are 0-based, add 1
    const year = date.getFullYear();

    // Convert 12-hour format to 24-hour format
    let hour24 = hour12;
    if (period === 'pm' && hour12 !== 12) {
      hour24 += 12;
    } else if (period === 'am' && hour12 === 12) {
      hour24 = 0;
    }

    const monthString = month.toString().padStart(2, '0');
    const dayString = day.toString().padStart(2, '0');
    const hourString = hour24.toString().padStart(2, '0');
    const minuteString = minutes.toString().padStart(2, '0');

    return moment.utc(`${year}-${monthString}-${dayString}T${hourString}:${minuteString}:00.000Z`);
  }

getCurrentDates(): ScheduleDatesInterface {
  const n: NgxIntervalDataGridRowModel[] | null | undefined = this.scheduleData?.values
    if (!n || !n[0].Sunday.start_date_time) {
      // This part will be never entered as we will generate blank schedule if provided nothing.
      const today = moment().tz(this.scheduleData?.regConfig?.timeZone ?? 'UTC');
      const startOfWeek = today.clone().startOf('week');
      return {
        Sunday: startOfWeek.clone(),
        Monday: startOfWeek.clone().add(1, 'day'),
        Tuesday: startOfWeek.clone().add(2, 'days'),
        Wednesday: startOfWeek.clone().add(3, 'days'),
        Thursday: startOfWeek.clone().add(4, 'days'),
        Friday: startOfWeek.clone().add(5, 'days'),
        Saturday: startOfWeek.clone().add(6, 'days')
      };
    }
    const timeZone = this.scheduleData?.regConfig?.timeZone ?? 'UTC';

    const scheduleDates: ScheduleDatesInterface = {
      Sunday: moment(moment.utc(n[0].Sunday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
      Monday: moment(moment.utc(n[0].Monday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
      Tuesday: moment(moment.utc(n[0].Tuesday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
      Wednesday: moment(moment.utc(n[0].Wednesday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
      Thursday: moment(moment.utc(n[0].Thursday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
      Friday: moment(moment.utc(n[0].Friday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
      Saturday: moment(moment.utc(n[0].Saturday.start_date_time).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')),
    };
    return scheduleDates
}

  protected apply(): void {
    if (this.checkIfDataIsValid()) {
      this.mixPanelService.clickBulkApply();

      const [startDateAndTime, endDateAndTime] = this.getStartAndEndDates();

      const output: BulkInput = {
        bulkType: BulkType.CUSTOM,
        inputType: this.bulkValue !== null && this.bulkValue !== undefined ? InputTypeEnum.VALUE : InputTypeEnum.OPTOUT,
        value: this.bulkValue,
        opt_out: this.optOut,
        week: undefined,
        startDateAndTime: startDateAndTime,
        endDateAndTime: endDateAndTime,
      }
      this.internalService.setBulkInputs(output);
      this.dialogRef.close();
    }
  }

  private getStartAndEndDates() {
    let startDate, endDate;
    if (this.selectedTabOption.name === 'Schedule') {
      startDate = this.getCurrentDates()[this.startDaySelectedPeriod as Week].toDate();
      endDate = this.getCurrentDates()[this.endDaySelectedPeriod as Week].toDate();
    } else {
      startDate = this.startDate.value?.start?.toDate();
      endDate = this.endDate.value?.start?.toDate();
    }
    if (!startDate || !endDate) return [undefined, undefined]
    const startDateWithTime = this.createMomentWithDateAndTime(startDate, this.startHour12, this.startMinute, this.startPeriod);
    const endDateWithTime = this.createMomentWithDateAndTime(endDate, this.endHour12, this.endMinute, this.endPeriod);
    return [startDateWithTime, endDateWithTime];
  }
}
